.loader {
  display: inline-block;
  /* border: 2px solid #f3f3f3; Light grey */
  border-top: 2px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.multi-line {
  color: white !important;
}

.valid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 100%;
  color: #4dbd74;
}

.error-step {
  color: white !important;
  border-color: #e65050 !important;
  background-color: #f17d7d !important;
}

.selected-step-error {
  background-color: red !important;
  border-color: red !important;
}

.custom-file-uploader {
  position: relative;
}
.custom-file-uploader input[type="file"] {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: default;
}

.csvInfo {
  font-size: 13px;
  background-color: #feffae;
  color: #303030;
  padding: 4px 6px 5px;
  border: 1px solid #ffe188;
  border-radius: 4px;
}

.wizard-button {
  background-color: #34b277;
  color: white;
  border: none;
}

.wizard-button:hover {
  background-color: #34b277;
  color: white;
  border: none;
}

.funnel-list-copy-to-clipboard {
  color: #34b277;
  background-color: #fff !important;
  border: none !important;
  outline: 0 !important;
  margin-left: 5px;
  padding: 0 6px;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
}

.funnel-list-copy-to-clipboard i {
  color: #9b9b9b;
  font-size: 13px;
  margin-right: 0 !important;
}

.tableHead {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.image-preview-wrapper {
  width: 100%;
  padding: 30px;
  text-align: center;
}

.image-preview {
  width: 100%;
}

.customer-form-label {
  font-size: 12px;
  margin-left: 5px;
  margin-bottom: -5px;
}

.input-message {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #6d6d6d;
}

.table-no-results {
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 64px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  margin: 1px;
  border-radius: 50%;
  border: 2px solid #34b277;
  border-color: #34b277 transparent #34b277 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#parse-loader {
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#parse-loader > div {
  padding-top: 7px;
}

#parse-loader > p {
  margin-left: 0px;
}

#google-business-search {
  margin-bottom: 40px;
}

.business-active-indicator {
  background-color: #28a745;
  min-width: 7px;
  min-height: 7px;
  border-radius: 50%;
  margin-right: 6px;
}

.business-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.business-inactive-indicator {
  background-color: rgb(167, 167, 167);
  min-width: 7px;
  min-height: 7px;
  border-radius: 50%;
  margin-right: 6px;
}

.customer-custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.customer-custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #34b277;
  background-color: #34b277;
}

.modal {
  z-index: 1050000000;
}

.zapier-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.zapier-label {
  margin-right: 8px;
  font-size: 14px;
  flex-shrink: 0;
}

.zapier-key {
  border: 1px solid rgba(128, 128, 128, 0.39);
  padding: 6px 8px;
  border-radius: 4px;
  height: 42px;
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
}

.zapier-copy-button > i {
  color: white;
}
