$primary: #33b377;
$grey: #c4c4c4;
$light-grey: #a0aec0;
$border-radius: 8px;

/* ===== Public Page ==== */
.company-logo-wrapper {
  display: flex;
  justify-content: center;
  .company-logo {
    height: 120px;
    object-fit: cover;
  }
}

.public-page {
  min-height: 100vh;
  p {
    font-weight: 500;
  }
  .login_banner {
    padding: 80px 20px 200px;
    margin-bottom: -140px;
    overflow: hidden;
    border-radius: 25px;
    background-color: $primary;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    > * {
      color: white;
    }

    h1 {
      font-size: 40px;
      font-weight: 600;
    }

    p {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .card-group {
    border-radius: 25px !important;
    box-shadow: none !important;
  }

  .card {
    border: 0 !important;
    margin: 0 auto !important;
    max-width: 420px !important;
    border-radius: 25px !important;
    box-shadow: 0px 5px 14px -6px $grey;
    .card-body {
      padding: 10px 25px 20px !important;

      a {
        text-decoration: none !important;
        color: $primary;
        font-weight: 500 !important;
        font-size: 14px;
      }

      .forgot-password {
        display: flex;
        justify-content: flex-end;
        a {
          font-size: 14px;
          color: $primary !important;
          line-height: 1;
        }
      }
    }
  }

  .signUpCard {
    max-width: 580px !important;

    .theme-btn {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// ====
.logo {
  width: 150px !important;
  height: 41px;
  object-fit: cover !important;
}

/* ===== SideBar Content ===== */
.customSideBar {
  .userProfile {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-bottom: 18px !important;
    border-bottom: 1px solid $grey !important;

    .img-avatar {
      width: 42px;
      height: 42px;
      min-width: 42px;
      object-fit: cover;
      margin-right: 8px;
      border: 1px solid rgba(0, 0, 0, 0.08);
    }

    .userProfileText {
      width: 100%;
      overflow: hidden;

      h2 {
        font-size: 14px;
        color: #555;
        margin-bottom: 2px;
        font-weight: 600;
      }

      p {
        font-size: 12px !important;
        color: #888;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .navbar-nav {
    align-items: flex-start !important;
    .nav-item {
      width: 100%;
      padding: 0 !important;
      text-align: left !important;
      align-items: flex-start !important;
      .nav-link {
        color: #333 !important;
        padding: 10px 0px !important;
        display: flex !important;
        align-items: center;
        width: 100% !important;
        background: none !important;
        span {
          margin-left: 15px;
        }

        i {
          font-size: 16px;
          min-width: 18px;
          margin-top: 2px;
        }
      }
    }

    &.navigation-list {
      .nav-item {
        border-radius: 6px;
        margin-bottom: 6px;

        .nav-link {
          padding: 10px 15px !important;
        }

        &.active,
        &:hover {
          span,
          i {
            color: $primary !important;
          }
        }

        &.active {
          background-color: rgba(52, 178, 119, 0.2) !important;
        }

        &:hover {
          background-color: rgba(52, 178, 119, 0.1) !important;
        }
      }
    }
  }
}

.logoutSideMenu {
  display: none;
}

// side menu css
.customSideBar {
  width: 250px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 8px 15px 10px;
  -webkit-box-shadow: inset -2px 0px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset -2px 0px 5px 0px rgba(0, 0, 0, 0.08);
  box-shadow: inset -2px 0px 5px 0px rgba(0, 0, 0, 0.08);
}

.sideMenuToggle {
  display: none;
}

.sideMenuToggle:checked ~ .mainContent,
.sideMenuToggle:checked ~ .sideMenuToggleIcon {
  left: 0;
}

.sideMenuToggleIcon {
  z-index: 9999;
  position: absolute;
  top: 17px;
  left: 280px;
  -moz-transition: left 0.5s ease;
  transition: left 0.5s ease;
  display: none;
}

.sideMenuToggleIcon i {
  color: #666;
  font-size: 22px;
}

.mainContent {
  position: absolute;
  top: 0;
  left: 250px;
  right: 0;
  bottom: 0;
  -moz-transition: left 0.5s ease;
  transition: left 0.5s ease;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .app {
    flex: 1;
    padding-bottom: 40px;
    min-height: auto !important;
  }
  // ===== Header =====
  header {
    i {
      color: #666 !important;
    }
  }

  .card {
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06) !important;
  }

  .shadow {
    box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.08) !important;
  }

  .upload-image-wrap {
    .imgUp {
      margin: 0 auto;

      .svg-inline--fa {
        height: 1.7rem;

        path {
          color: rgba(3, 2, 41, 0.7);
        }
      }
    }

    label {
      cursor: pointer;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .theme-btn.outline {
      padding: 0 30px !important;
    }
  }

  .new-business {
    .upload-image-wrap .profile-pg-img {
      display: flex;
      justify-content: center;
      .imagePreview {
        height: 100px;
        width: 100px;
      }
    }

    .svg-inline--fa {
      height: 1rem !important;
    }
  }

  input:not(.form-check-input),
  .modal-footer .btn,
  .theme-btn {
    height: 42px !important;
  }

  .modal-footer .btn {
    border-radius: 8px !important;
    margin: 0;
    margin-left: 0.75rem;
  }
}

.MuiDialogActions-root {
  .btn {
    height: 42px !important;
    border-radius: 8px !important;
  }
}

// ===========
.table {
  .table-communication {
    span {
      position: absolute;
      top: -9px;
      right: -12px;
      font-size: 9px;
      color: white !important;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      line-height: 1;
      background-color: $primary;
    }
  }

  .round-btn {
    border-radius: 100%;
    width: 29px;
    height: 29px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary;
    background-color: transparent;
    color: $primary !important;
    i {
      color: $primary !important;
    }
  }
}

.bg-color-input {
  input {
    width: 66px;
    height: 50px;
    border: none !important;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
  }
}

// =========
.MuiSlider-root {
  span[class^="PrivateValueLabel-circle"] {
    position: relative;
    background-color: $primary !important;

    &::before {
      content: "";
      position: absolute;
      top: 30%;
      left: 30%;
      width: 40%;
      height: 40%;
      border-radius: 100%;
      background-color: white;
    }

    span[class^="PrivateValueLabel-label"] {
      display: none !important;
    }
  }
}

// ====== vertical-tab ======
.vertical-tab {
  display: flex;
  margin: 25px 0;

  .nav-tabs {
    border: none !important;

    .nav-item {
      margin: 6px 0 !important;

      .nav-link {
        position: relative;
        background-color: lighten($grey, 15%);
        width: 100%;
        font-size: 14px;
        text-align: left;
        border-radius: 6px !important;
        color: black !important;

        &.active,
        &:hover {
          color: white !important;
          background-color: $primary !important;
          border-color: $primary !important;
        }
      }

      &:first-child {
        margin-top: 0 !important;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  .tab-content {
    border-radius: $border-radius !important;
    width: 100% !important;
    padding: 20px;

    .form-group {
      display: flex;
      align-items: center;
    }
  }
}

.MuiSlider-markLabel {
  font-size: 14px !important;
  top: 35px !important;
  font-family: "Mulish", sans-serif !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  > * {
    font-size: 14px !important;
  }
}

.edit-funnel-page {
  .card {
    padding-top: 0 !important;
    .card-header {
      position: relative;
    }
  }
}

#addCustomers > div {
  max-width: 500px !important;
}

.recentFeedbackWrap {
  margin-bottom: 0;

  li {
    padding: 0;
    padding-right: 10px;
    margin: 7px 0;
    display: flex;
    align-items: center;
    background-color: #f5f6f8;
    border-top-left-radius: 44px !important;
    border-bottom-left-radius: 44px !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border: 0;

    &:first-child {
      margin-top: 0;
    }

    h5 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
    }

    .starRating,
    h6 {
      font-size: 14px;
      font-weight: 600;
      margin-top: 2px;
      margin-bottom: 0;
      color: #444;
    }

    .feedbackDate {
      font-size: 13px;
      color: #777;
      margin-top: -1px;
      margin-left: 10px;
    }

    .starRating {
      i {
        color: #ffc107;
        font-size: 13px;
      }
    }

    p {
      margin-top: 4px;
      font-size: 13px !important;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 19px;
      /* fallback */
      max-height: 38px;
      /* fallback */
      -webkit-line-clamp: 2;
      /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    .userProfile {
      height: 90px;
      width: 90px;
      min-width: 80px;
      border: 1px solid rgba(0, 0, 0, 0.04);
      border-radius: 50%;
      margin-right: 12px;
      object-fit: cover;
    }

    .feedbackIcon {
      height: 15px;
      margin-top: 1px;
    }
  }
}

.dashboardPgFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logsFilter {
  position: absolute;
  right: 12px;
  top: 8px;
  height: unset;
}

.logsWrap {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 14px 0 13px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  h4 {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #212529 !important;
    padding-bottom: 0 !important;
    padding: 0 !important;
    border-bottom: 0 !important;
    margin-top: 0 !important;
    background-color: transparent !important;
  }

  h5 {
    font-size: 14px;
    font-weight: 600;
    color: #444;
    line-height: 1;
    margin-bottom: 0px;
  }

  p {
    font-size: 13px !important;
    color: #777;
    margin-bottom: 0;
  }

  .logDate {
    font-size: 12px;
    color: #777;
    margin-left: 8px;
  }

  .readMore {
    color: $primary;
    display: inline-block;
    cursor: pointer;
    margin-top: -4px;
    padding: 0 0 0 3px !important;
    font-size: 13px;
  }
}

.backBtn {
  width: 22px;
  height: 22px;
  border-radius: 50% !important;
  padding: 0 !important;
  border: 0;
  line-height: 27px !important;
  margin-right: 8px;

  .fa {
    margin-right: 0 !important;
    color: $light-grey !important;
    margin-left: -1px;
  }
}

.kioskPgHeader {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 16px;
  padding-bottom: 15px;
  box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.2);

  h4 {
    font-weight: 600;
    margin-bottom: 4px;
    font-size: 20px;
  }

  p {
    margin-bottom: 0;
  }

  img {
    height: 80px;
    margin: 0 auto 10px;
  }
}

.scanMeTxt {
  font-size: 18px !important;
  color: #666;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  letter-spacing: 0.3px;
}

.table-responsive {
  margin-bottom: 10px;
}

.custom-table {
  margin-bottom: 8px;
}

select.countryCodeKiosk {
  min-width: 125px !important;
}

select.countryCodePreferences {
  border-radius: 0 !important;
  margin-right: -1px;
  min-width: 150px !important;
}

.businessSearchResults {
  li {
    padding: 9px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0;

    h5 {
      font-size: 14px;
      margin-bottom: 4px !important;
      color: #212529 !important;
    }

    p {
      margin-bottom: 0;
      color: #666;
      font-size: 12px !important;
    }

    .btn {
      margin-left: 10px;
    }
  }
}

@media all and (max-width: 1199.98px) {
  .navbar {
    justify-content: center;
  }

  .sideMenuToggleIcon {
    display: initial;
    left: 32px;
  }

  .mainContent {
    left: 0;
  }

  .sideMenuToggle:checked ~ .mainContent {
    left: 250px;
  }

  .sideMenuToggle:checked ~ .sideMenuToggleIcon {
    left: 282px;
  }

  .logoutSideMenu {
    display: flex;
  }
}

@media all and (max-width: 991.98px) {
  .sideMenuToggle:checked ~ .sideMenuToggleIcon {
    left: 272px;
  }

  .sideMenuToggleIcon {
    left: 25px;
  }

  .dashboardPgFilter {
    flex-direction: column;
    align-items: flex-start;
  }
}
