$primary: #33b377;
$grey: #c4c4c4;
$grey-dark: #a0aec0;

a {
  color: $primary;
  text-decoration: none !important;
  &:hover {
    color: $primary;
  }
}

.fs-10 {
  font-size: 10px !important;
}

.fs-14,
textarea,
.list-group,
p {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.text-grey {
  color: $grey !important;
}

.text-grey-dark {
  color: $grey-dark !important;
}

.text-theme {
  color: $primary !important;
}

label {
  font-size: 14px !important;
  font-weight: 400;
  color: #2d3748 !important;
  box-shadow: none !important;
  margin-bottom: 5px;
}

.mb-n1 {
  margin-bottom: -1px !important;
}

.mw-130 {
  min-width: 130px !important;
}

.brl-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.brr-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.rounded {
  border-radius: 8px !important;
}

// ===============
.btn,
input {
  font-size: 14px;
  box-shadow: none !important;
}

.button-wrap-profile-new .btn,
.theme-btn,
.btn-primary {
  background-color: $primary !important;
  border: 1px solid $primary !important;
  color: white !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  padding: 4px 12px !important;
  font-size: 14px !important;
  height: 42px !important;
  line-height: 1 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  border-radius: 8px !important;

  i {
    font-size: 14px;
    color: white !important;
  }

  &.outline {
    background-color: transparent !important;
    color: $primary !important;

    i {
      color: $primary !important;
    }

    &.text-dark {
      border-color: rgba(0, 0, 0, 0.2) !important;
      color: #4f4f4f !important;

      i {
        color: #4f4f4f !important;
      }
    }
  }

  &.brl-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &.brr-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.cancel-btn,
.btn-secondary {
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  padding: 4px 12px !important;
  font-size: 14px !important;
  height: 42px !important;
  line-height: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  background-color: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  color: #4f4f4f !important;
  font-family: "Mulish", sans-serif !important;

  i,
  span {
    color: #4f4f4f !important;
    text-transform: capitalize;
  }

  &.outline {
    background-color: transparent !important;
    color: $primary !important;

    i {
      color: $primary !important;
    }

    &.text-dark {
      border-color: rgba(0, 0, 0, 0.2) !important;
      color: #4f4f4f !important;

      i {
        color: #4f4f4f !important;
      }
    }
  }
}

// ===========
.form-control {
  min-width: 100px;
  height: 42px;
  font-size: 14px !important;
  border-radius: 8px !important;
  background-color: #fff !important;
  box-shadow: none !important;
  padding: 7px !important;
  border-color: #b5b6b6 !important;
}

select.form-control {
  width: auto !important;
  padding-right: 18px !important;
}

select {
  background: url("data:image/svg+xml;utf8,<svg fill='grey' height='20' viewBox='0 0 24 24' width='20' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.selectFunnel,
select.selectCountryCode {
  width: 100% !important;
}

select.selectBusiness {
  width: 80% !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-select {
  border-radius: 8px !important;
  border-color: #b5b6b6 !important;
  height: 42px;
  font-size: 14px !important;
  padding: 7px !important;
  box-shadow: none !important;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .button-wrap-profile-new {
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;

    > *:not(:last-child) {
      margin-right: 1rem;
    }

    .form-group {
      margin-bottom: 0;
      & ~ .btn {
        margin-bottom: 0;
      }
    }
  }
}

.countrySelect {
  display: flex;
  align-items: center;

  select {
    border-right: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.form-check {
  display: flex;
  align-items: center;

  label {
    margin-top: 4px;
    margin-left: 4px;
  }

  label,
  input {
    cursor: pointer;
  }
}

/* ====== Table ====== */
.table-card {
  padding: 0 !important;
}

.table {
  width: 100%;
  margin-bottom: 1rem;

  tbody {
    border: none !important;
  }

  th,
  td {
    text-align: left !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    vertical-align: bottom;
    --bs-table-accent-bg: unset !important;
    background-color: #f7f7fc !important;
    border: none !important;
    padding: 12px !important;

    &:first-child {
      padding-left: 15px !important;
    }

    &:last-child {
      padding-right: 15px !important;
    }
  }

  th {
    .tableHead {
      font-weight: 600 !important;
      color: rgba(17, 20, 45, 0.5) !important;
    }
  }

  td {
    color: rgba(17, 20, 45, 0.8) !important;
    font-weight: 400 !important;
    background-color: white !important;
    border-top: 1px solid #e1e1fb !important;
    border-bottom: 1px solid #e1e1fb !important;

    span {
      color: rgba(17, 20, 45, 0.8) !important;
    }

    select {
      color: $primary !important;
    }

    i {
      font-size: 14px !important;
    }

    span.fs-16,
    i.fs-16 {
      font-size: 16px !important;
    }

    .form-group {
      margin-bottom: 0 !important;
    }
  }

  .btn {
    font-size: 14px !important;
    box-shadow: none !important;

    &.theme-btn {
      border-radius: 4px !important;
      border-color: #e1e1fb !important;
      background-color: transparent !important;
      color: $primary !important;
      padding: 7px 25px !important;
      width: auto !important;
      height: auto !important;
      line-height: 1 !important;
    }
  }

  .badge {
    background-color: transparent !important;
    line-height: 1 !important;
  }
}

// ===== Pagination ======
.pagination {
  li {
    display: flex;
    align-items: center;
    a {
      font-size: 14px !important;
      padding: 10px 15px !important;
      background-color: transparent !important;
      color: black !important;
      border: 1px solid #dedede;

      &[aria-label="Go to next page"],
      &[aria-label="Go to previous page"] {
        color: $primary !important;
        border-color: $primary !important;
      }

      &[aria-label="Go to last page"],
      &[aria-label="Go to first page"] {
        display: none !important;
      }

      &[aria-label="Go to previous page"] {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &[aria-label="Go to next page"] {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    &.active {
      a {
        background-color: $primary !important;
        color: white !important;
        border-color: $primary;
      }
    }
  }
}

// ======= React Switch ======
.react-switch-bg {
  height: 25px !important;
}

.react-switch-handle {
  height: 19px !important;
  width: 19px !important;
  top: 3px !important;
  left: 3px;
}

.ReactCrop {
  width: 300px;
  height: 300px;
  display: block !important;
  margin: 0 auto;
  border: 1px solid $grey;

  * {
    width: 100%;
    height: 100%;
  }
}

// ===== Breadcrumb =====
.breadcrumb {
  background-color: transparent !important;
  a {
    color: $primary !important;
  }
}

// ==== Upload Img =====
.upload-image-wrap {
  margin-bottom: 20px;
  margin-top: 7px;

  .imagePreview {
    width: 100%;
    height: 130px;
    background-color: #f3f3f4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-bottom: 4px;
    border: 1px solid $grey;

    .profile-img {
      /* height: 130px; */
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  .imgUp {
    margin: 0 auto 15px;
  }

  .recommended-resolution {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
    font-size: 12px;

    span {
      color: #c4c4c4;
    }
  }

  p {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: black;
  }
}
