@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700;800;900&family=Nunito:wght@300;400;500;700;800&display=swap");

body {
  font-family: "Mulish", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ==== Variables ==== */
:root {
  --primary: #33b377;
  --secondary: #328b66;
  --light-grey: #a0aec0;
  --border-radius: 8px;
  --white: #fff;
  --grey: #333;
}

iframe {
  display: none !important;
}

.app,
app-dashboard,
app-root {
  background-color: var(--white);
}

.mainContent .app,
.mainContent app-dashboard,
.mainContent app-root {
  background-color: #f5f6f8;
}

.mainContent .app {
  padding-bottom: 0 !important;
}

.app {
  overflow: auto;
}

/* ===== Input Group ==== */
.input-group-prepend .input-group-text,
.input-group input {
  border-top-left-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group-append .input-group-text,
.input-group-prepend ~ input {
  border-top-right-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.input-group-prepend .input-group-text {
  border-right: none !important;
}

.input-group-append .input-group-text {
  border-left: none !important;
}

.input-group .input-group-text {
  padding: 5px 15px !important;
  background-color: white !important;
  border-color: #b5b6b6 !important;
}

.input-group-text i {
  color: var(--light-grey) !important;
  cursor: pointer;
}

input.rounded-0 {
  border-radius: 0 !important;
}

/* Change the AutoComplete Color to white */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
.form-control,
.form-control::placeholder {
  font-size: 14px !important;
  font-weight: 400;
  color: rgba(45, 55, 72, 0.6) !important;
  box-shadow: 0 0 0 30px white inset !important;
}

/* ==== Custom  Control =====*/
.custom-control-label {
  line-height: 1.8;
  cursor: pointer;
  padding-left: 5px;
}

.custom-control-input:focus ~ .custom-control-label::before,
.custom-control-input:focus ~ .custom-control-label::after {
  box-shadow: none !important;
}

.custom-switch .custom-control-label::before {
  border-color: var(--secondary) !important;
  width: 2rem;
}

.custom-switch .custom-control-label::after {
  background-color: var(--secondary) !important;
  top: calc(0.15625rem + 3px);
  left: calc(-2.25rem + 4px);
  width: calc(1rem - 6px);
  height: calc(1rem - 6px);
  background-image: none !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--secondary) !important;
  background-color: var(--secondary) !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: white !important;
  transform: translateX(0.9rem) !important;
  background-image: url(/static/media/check.0ee7d423.svg) !important;
}

/* css for login page */

#login-page .card-group,
.forgot-password-wrap,
#register-page .card-group {
  box-shadow: 0 0 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.03), 0 7px 40px rgba(90, 97, 105, 0.1);
}

/* css for register page */
.green-bg {
  background: rgba(52, 178, 119, 0.77);
  border-color: rgba(52, 178, 119, 0.77);
}

.green-bg * {
  color: var(--white);
}

.cancel-btn {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.3px;
  height: 38px;
  padding: 0;
  line-height: 38px;
}

.text-underline:hover {
  text-decoration: underline;
}

#register-page i.icon-camera,
#profile-page i.icon-camera {
  font-size: 36px;
  color: var(--grey);
  margin-top: 5px;
}

/* css for profile page */
#profile-page .main,
#profile-page .app-footer {
  margin: 0;
}

.app-footer {
  margin-top: 20px;
  padding: 0 1.25rem;
  font-size: 14px;
  border: none !important;
  -ms-flex: 0 0 40px;
  flex: 0 0 40px;
  background: transparent;
}

.mainContent .app-footer {
  background: #f5f6f8;
}

.app-footer * {
  color: #666;
}

.app-footer a {
  color: #33b377 !important;
}

.ls-logo {
  height: 15px;
  margin-top: -2px;
  margin-left: 4px;
  margin-right: 5px;
}

.app-footer .ls-name {
  color: #31c5c7 !important;
  text-decoration: none !important;
}

.app-header .header-icon {
  padding: 0 10px;
  font-weight: 600;
  color: var(--grey);
  text-decoration: none !important;
  display: inline-block;
  position: relative;
}

.app-header .navbar-nav .notification i {
  position: relative;
}

.app-header .navbar-nav .notification i.active::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: var(--primary);
}

.app-header {
  /* height: 70px; */
  border: 0;
}

.app-header .header-icon i {
  font-size: 18px;
}

#profile-page .card-header {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  /* background-color: #f0f3f5; */
  background: #f0f0f0;
  color: var(--grey);
  letter-spacing: 0.3px;
}

#profile-page .card-body {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

#profile-page .profile-head {
  color: var(--grey);
  letter-spacing: 0.3px;
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}

#profile-page .card {
  background-color: var(--white);
  background-clip: border-box;
  border: none;
  border-radius: var(--border-radius);
  overflow: hidden;
}

#profile-page .upload-image-wrap .btn-primary {
  margin-bottom: 0;
  border: 1px solid #e4e4e4;
  border-top: 0;
}

#profile-page .edit-link {
  font-size: 16px;
  font-weight: 600;
  color: #1d9bc6;
  text-decoration: none;
  border: 1px solid #1d9bc6;
  right: 15px;
  top: 8px;
  position: absolute;
  line-height: 1;
  padding: 6px 12px 7px;
}

#profile-page .edit-link i {
  margin-right: 2px;
  font-size: 14px;
  vertical-align: 1px;
}

#profile-page .button-wrap-profile {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}

#profile-page .button-wrap-profile-new {
  display: flex;
}

#profile-page .button-wrap-profile button {
  height: 50px;
  background-color: var(--white);
  border-color: var(--grey);
  color: var(--grey);
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 0 10px;
  width: 215px;
  font-size: 15px;
  transition: all 0.3s;
}

#profile-page .button-wrap-profile button:hover,
#profile-page .button-wrap-profile button:focus,
#profile-page .button-wrap-profile button:active {
  color: var(--white);
  background: #505050;
  border-color: #505050;
  transition: all 0.3s;
}

#profile-page .button-wrap-profile-new button {
  background-color: var(--white);
  border-color: var(--grey);
  color: var(--grey);
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 8.5px 16px 7.5px;
  font-size: 15px;
}

i.fa.fa-external-link {
  margin-right: 10px;
  font-size: 17px;
  vertical-align: -1px;
}

.mt-60 {
  margin-top: 60px;
}

/* css for customers page */
.mt-40 {
  margin-top: 40px;
}

.customer-page .button-wrap-profile button {
  height: 39px !important;
  width: 150px !important;
}

.customer-page .upload-btn,
.video-upload {
  background-color: var(--white) !important;
  border-color: var(--primary) !important;
  color: var(--primary) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  margin-bottom: 0 !important;
  padding: 0 8px !important;
  height: 38px;
  cursor: pointer !important;
  line-height: 36px !important;
  border-radius: 6px !important;
}

.video-upload input:not(.form-check-input) {
  display: none;
}

.customer-page .upload-btn .fa,
.video-upload .fa {
  color: var(--primary);
}

.customer-page .fa,
.video-upload .fa {
  margin-right: 6px;
  font-size: 14px;
}

.customer-page .card-body {
  width: 100% !important;
  display: block !important;
}

.customer-page .card {
  height: unset !important;
  padding-top: 5px;
}

.customer-page i.fa.fa-trash-o {
  color: #f63c3a;
  font-size: 15px;
}

.customer-page i.fa.fa-pencil {
  color: var(--grey);
  font-size: 15px;
}

.customer-page i.fa.fa-plus-square-o {
  color: var(--grey);
  font-size: 16px;
}

.customer-page button.btn.btn-link {
  padding: 0 5px;
}

.customer-page .button-wrap-profile1 {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.customer-page .button-wrap-profile1 button,
.customer-page .button-wrap-profile1 a {
  background-color: var(--white) !important;
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 0 10px;
  width: 190px;
  font-size: 15px !important;
  margin-right: 15px;
  margin-left: 2px;
  height: 46px;
  line-height: 46px;
  border-radius: var(--border-radius);
  text-align: center;
  text-decoration: none !important;
}

.customer-page .button-wrap-profile1 button i,
.customer-page .button-wrap-profile1 a i {
  color: var(--primary) !important;
  font-size: 15px;
  margin-right: 6px;
}

/* ===== Button ==== */
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn:focus,
.btn.focus {
  outline: unset;
  box-shadow: unset;
}

.customer-page .select-box-customer:first-child {
  margin-left: 0;
}

.customer-page .select-box-customer:last-child {
  margin-right: 0;
}

.app-header h6 {
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.3px;
}

.app-header h6 > span {
  color: var(--grey);
}

.reset-password-btn,
.back-login-btn {
  display: block;
  margin: 30px auto 15px;
  font-size: 15px;
}

.back-login-btn {
  margin: 15px auto 5px;
}

.back-login-btn:hover {
  text-decoration: underline;
}

/* css for setup-wizard page */

.setup-wizard-page .nav-pills .nav-link {
  font-weight: 600;
  font-size: 16px;
  color: var(--grey);
}

.setup-wizard-page .nav-pills .nav-link.active {
  background-color: var(--white);
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
  margin: 0 10px 7px;
}

.setup-wizard-page .tab-content .tab-pane {
  width: 80%;
  margin: 15px auto;
}

.setup-wizard-page .tab-content .tab-pane h5 {
  margin-bottom: 20px;
  color: #20a8d8;
}

.setup-wizard-page .card {
  height: unset !important;
}

.setup-wizard-page .sorry-text {
  color: #f63c3a;
  font-weight: 600;
  margin: 0 auto;
  font-size: 16px;
  text-align: center;
  margin-bottom: 15px;
}

.setup-wizard-page .search-text {
  color: var(--grey);
  font-weight: 600;
  margin: 0;
  margin-left: 20px;
  font-size: 16px;
}

.setup-wizard-page .hide-text {
  display: none !important;
}

.spinner {
  height: 25px;
  width: 25px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.setup-wizard-page .button-wrap-profile2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.spinner--3 {
  border: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom-color: var(--white);
  border-top-color: var(--white);
  animation: spinner3 800ms ease infinite;
}

@keyframes spinner3 {
  to {
    transform: rotate(360deg);
  }
}

.setup-wizard-page .address-p {
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.7px;
  line-height: 1.6;
}

.setup-wizard-page i.fa.fa-thumbs-up {
  margin-right: 5px;
}

.setup-wizard-page i.fa.fa-thumbs-down {
  margin-left: 5px;
}

.setup-wizard-page .type-h3 {
  font-weight: 600;
  font-size: 19px;
  color: var(--grey);
  margin-bottom: 10px;
  margin-top: 40px;
}

.setup-wizard-page .type-btn {
  background-color: transparent;
  color: #20a8d8;
  border-width: 2px;
  font-weight: 600;
}

/* css for setup-wizard-form-page page */
.setup-wizard-form-page .first-phase {
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
  border: none;
  width: 80%;
  background-color: var(--white);
}

.setup-wizard-form-page .first-phase .form-wrap {
  width: 80%;
  margin: 30px auto;
}

.setup-wizard-form-page .create-url-a {
  margin-top: 7px;
  margin-left: 5px;
  text-decoration: underline;
  font-weight: 600;
  color: #167495;
  text-align: right;
}

.setup-wizard-form-page .form-btn-popover {
  padding-left: 0;
  padding-top: 0;
}

.setup-wizard-form-page .form-btn-popover i.fa {
  color: var(--grey);
  font-size: 18px;
}

/* css for positive feedback page */
.mail-form-wrap,
.phone-form-wrap {
  display: flex;
  width: 100%;
}

.phone-form-wrap .form-control,
.mail-form-wrap .form-control,
.phone-form-wrap .input-group-text,
.mail-form-wrap .input-group-text {
  border-radius: 0;
}

.mail-form-wrap .input-group-prepend ~ div {
  border-color: #b5b6b6 !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.mail-form-wrap .input-group-prepend ~ div input,
.mail-form-wrap .input-group-prepend ~ div span {
  font-family: "Mulish", sans-serif !important;
}

.mail-form-wrap .input-group-prepend ~ div span {
  font-weight: 700 !important;
  line-height: 1;
}

.mail-form-wrap .input-group-prepend ~ div span ~ span {
  margin-left: 0 !important;
}

.reset-option,
.delete-option,
.remove-option,
.edit-option {
  padding: 2px 10px 3px;
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 50% !important;
  transform: translateY(-50%);
  text-transform: uppercase;
}

.delete-option,
.remove-option {
  top: 8px;
}

.feedback-card {
  height: unset !important;
}

.save-card-body {
  background-color: var(--white);
  color: #20a8d8;
  font-weight: 600;
  font-size: 16px;
  padding: 7px 25px 8px;
  letter-spacing: 0.5px;
  display: block;
  margin: 20px auto 8px;
}

.feedback-page .upload-btn {
  color: #1d9bc6;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 5px 5px 6px;
  width: 145px;
  font-size: 14px;
  margin-left: 0;
  margin-bottom: 22px;
  margin-top: 5px;
  background-color: transparent;
  border-color: #1d9bc6;
  cursor: pointer;
}

.feedback-page .upload-btn:hover,
.feedback-page .upload-btn:focus,
.feedback-page .upload-btn:active {
  color: var(--white);
  background-color: #1d9bc6;
  border-color: #1d9bc6;
}

.feedback-page i.fa.fa-upload {
  margin-right: 6px;
}

.feedback-page .button-wrap-profile1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  margin-top: 45px;
}

.feedback-page .button-wrap-profile1 button {
  background-color: var(--white);
  border-color: var(--grey);
  color: var(--grey);
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 7px 16px 8px;
  width: unset;
  font-size: 15px;
}

.feedback-page .button-wrap-profile1 button:hover,
.feedback-page .button-wrap-profile1 button:focus,
.feedback-page .button-wrap-profile1 button:active {
  background: #505050 !important;
  color: var(--white);
  border-color: #505050 !important;
}

.feedback-page .card-header {
  line-height: 1.8;
  padding: 10px 70px 8px;
  position: relative;
}

.added-module {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 25px;
}

.added-module .module-added {
  background: var(--primary);
  border: 1px solid var(--primary);
  color: white;
  font-size: 14px;
  letter-spacing: 0.3px;
  min-width: 120px;
  max-width: 120px;
  font-weight: 500;
  margin-bottom: 12px;
  border-radius: var(--border-radius);
  padding: 7px 12px;
  text-align: center;
  cursor: pointer;
  text-transform: capitalize;
  outline: 0 !important;
}

/* css for positive sequence-page */
#sequence-page {
  background-color: unset !important;
}

#profile-page .add-module-wrap,
#profile-page .sequence-builder {
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1) !important;
  height: 340px !important;
  margin-right: 25px;
}

#profile-page .add-module-wrap {
  width: 200px;
}

#profile-page .sequence-builder {
  height: unset !important;
  margin-right: 0 !important;
  flex-grow: 1;
}

.custom-left-margin {
  margin-left: 225px;
}

#profile-page .add-module-wrap .card-header,
#profile-page .sequence-builder .card-header,
#profile-page .feedback-card .card-header {
  font-size: 16px;
  background-color: #f0f3f5;
  border-bottom: 1px solid #c8ced3;
}

#profile-page .feedback-card {
  margin-left: 225px;
  margin-top: 20px;
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1) !important;
}

#profile-page .sequence-builder .inner-card {
  width: 100%;
  border: 1px solid #e2e2e2;
  margin-bottom: 25px;
}

#profile-page .sequence-builder .inner-card:last-child {
  margin-bottom: 22px;
}

#profile-page .sequence-builder .inner-card .card-header {
  background: #f7f7f7;
  border-bottom: 1px solid #ddd;
  padding: 9px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#sequence-page .inner-card .edit-card-header {
  padding: 2px 10px;
  background-color: var(--white);
  border-color: var(--white);
  color: var(--grey);
  font-weight: 600;
}

#sequence-page .inner-card-header {
  background-color: var(--grey);
  color: var(--white);
  font-size: 14px;
}

#sequence-page .inner-card-padding {
  padding: 40px;
}

.add-module-wrap .card-body {
  justify-content: space-evenly !important;
}

.add-module-wrap .card-body .module {
  border-color: var(--primary);
  background-color: var(--primary);
  color: white;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 14px;
  width: 100% !important;
  text-transform: capitalize;
  padding: 8px 10px;
  border-radius: var(--border-radius);
  border: 1px solid var(--primary);
  text-align: center;
  cursor: pointer;
  margin-bottom: 12px;
}

.add-module-wrap .card-body .module:hover {
  background: var(--primary);
  color: var(--white);
  border-color: var(--primary);
  /* transition: all 0.3s; */
}

#sequence-page .tab-content {
  border: none;
}

#sequence-page .remove-card-header {
  padding: 2px 10px;
  background-color: var(--white);
  border-color: #f63c3a;
  color: #f63c3a;
  font-weight: 600;
}

#sequence-page .nav-pills .nav-link {
  margin: 8px 0;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.negative-feedback-page .add-module-wrap {
  height: 70% !important;
}

#sequence-page .edit-action-btn {
  padding: 0;
  color: var(--white);
  margin-left: 11px;
  vertical-align: 0;
}

#sequence-page .delete-card-header {
  color: #f63c3a;
  padding: 2px 5px;
  font-size: 13px;
  background-color: var(--white);
  border-color: var(--white);
  font-weight: 600;
}

/* css for feedback alert page */
.feedback-alert .form-group {
  display: block;
  margin-bottom: 15px;
}

.feedback-alert .form-group input,
.customCheckbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.feedback-alert .form-group label,
.customCheckbox label {
  position: relative;
  cursor: pointer;
  margin: 0;
  font-size: 15px;
  letter-spacing: 0.7px;
  line-height: 1.6;
}

.feedback-alert .form-group label:before,
.customCheckbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid var(--primary);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: -6px;
  cursor: pointer;
  margin-right: 15px;
}

.feedback-alert .form-group input:checked + label:after,
.customCheckbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid var(--primary);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.feedback-alert .notify-form {
  display: flex;
}

.feedback-alert .form-group {
  margin-right: 25px;
}

.feedback-alert .review-wrap {
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  align-items: center;
}

.logo {
  height: 50px;
  object-fit: cover;
}

.button-wrap-profile-new .template-btn-1 {
  color: var(--grey) !important;
  border: none;
  text-decoration: underline;
  padding: 8px 0 !important;
}

.border-radius-none {
  border-radius: unset !important;
}

.main {
  padding-top: 20px;
}

.app-header .nav-item {
  font-size: 15px;
  min-width: unset;
  padding: 0 10px;
  line-height: 1;
}

.app-header .nav-item .nav-link > .img-avatar {
  height: 36px;
  object-fit: cover;
  width: 36px;
  margin: 0 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.custom-table .badge {
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  padding: 0 5px;
  line-height: 23px;
}

.modal-header .close:focus {
  outline: unset;
}

.modal-header,
.modal-header .close {
  padding: 0.75rem 1rem 0.6rem !important;
}

.modal-header .close {
  margin: -0.8rem -1rem -0.6rem auto;
}

.modal-header .close span {
  color: var(--white);
  opacity: 1 !important;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: none;
}

.sorry-card-wrap {
  box-shadow: none !important;
  border: 1px solid #c8ced3 !important;
}

.width-45 {
  width: 45%;
}

#newaddCustomers .modal-header {
  border: none;
}

#newaddCustomers .modal-body p {
  margin: 0;
  font-size: 19px;
  padding-bottom: 20px;
  padding-top: 0;
  text-align: center;
  letter-spacing: 0.5px;
}

#newaddCustomers .modal-body p > a {
  font-weight: 500;
  text-decoration: underline;
}

.edit-input-grp p:first-child {
  color: #017e7f;
  font-size: 17px;
  font-weight: 600;
  margin-right: 10px;
  letter-spacing: 0.5px;
}

.edit-input-grp p {
  color: #555;
  font-size: 17px;
}

.add-manually-btn {
  background-color: var(--primary);
  color: white;
  border: none;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 8px 16px 8px;
  font-size: 15px;
  display: block !important;
  margin: 20px auto 10px;
}

.search-results-txt {
  font-size: 16px !important;
  font-weight: 500;
  color: var(--grey);
  margin-top: 12px;
  margin-bottom: 5px;
}

.create-url {
  margin-left: 8px;
  text-decoration: underline;
  font-weight: 600;
  color: #505050 !important;
  text-align: right;
  font-size: 14px;
}

.form-btn-popover .fa {
  color: var(--grey);
  font-size: 14px;
}

.feedback-funnels {
  display: inline-flex;
  background: rgba(51, 179, 119, 0.09);
  border-radius: 12px;
}

.feedback-funnels input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.feedback-funnels .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 125px;
  transition: all 0.3s;
  padding: 17px 5px;
  margin-bottom: 0;
  border: 0;
}

.feedback-funnels label.btn.active {
  background: rgba(51, 179, 119, 0.26) !important;
  border-radius: 12px;
}

.btn-outline-theme {
  background: var(--white);
  border-color: var(--primary);
  color: var(--primary);
}

.btn-outline-theme:hover,
.btn-outline-theme:active,
.btn-outline-theme:focus {
  background: var(--primary);
  border-color: var(--primary);
  color: var(--white);
}

.added-module-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.switch-success .switch-input:checked + .switch-slider {
  background-color: var(--primary);
  border-color: var(--primary);
}

.feedback-card .card-body {
  display: initial !important;
}

.add-new-section {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: var(--white) !important;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 0 18px;
  height: 42px;
  line-height: 42px;
  font-size: 15px;
  display: block;
  margin: 10px auto 15px;
  transition: all 0.4s;
}

.add-new-section .fa {
  margin-right: 5px;
  color: var(--white);
}

/* tooltip */
.info-icon {
  font-size: 16px;
  position: absolute;
  left: -25px;
  top: 10px;
  cursor: pointer;
}

.tooltip-inner * {
  color: var(--white);
}

.info-icon-tooltip {
  font-size: 16px;
  margin-left: 2px;
  vertical-align: text-top;
}

.delete-video {
  padding: 8px 8px 9px;
  float: right;
  font-weight: 500;
  font-size: 15px;
  color: #f86c6b !important;
  text-decoration: none !important;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  margin-left: 12px;
}

/* summernote wysiwyg */
#profile-page .sequence-builder .inner-card .card-header.note-toolbar {
  display: initial;
}

/*  ==== Setup Wizard ===== */
.setup-wizard-nav-wrap {
  width: 100%;
  margin-bottom: 50px;
  border-top: none;
}

.setup-wizard-nav {
  border: 0;
  border-bottom: none !important;
  padding: 0px;
  background: #f5f5f5;
  border-radius: 0;
  list-style: none;
  overflow: hidden;
}

.setup-wizard-page .tab-content .tab-pane {
  margin: 0 auto;
  padding: 15px;
  width: 100%;
}

.setup-wizard-nav li + li:before {
  padding: 0;
}

/* ============== */
.setup-wizard-nav > li > a,
.setup-wizard-nav > li {
  margin-bottom: 0 !important;
  position: relative;
}

.setup-wizard-nav > li > a,
.setup-wizard-nav > li > a div {
  color: var(--grey) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.stepInfo {
  position: absolute;
  right: 1px;
  top: 10px;
  font-size: 13px;
  z-index: 999;
}

.setup-wizard-nav > li > a,
.setup-wizard-nav > li > a:hover {
  padding: 14px 15px 14px 46px !important;
  position: relative;
  display: block;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0 !important;
  outline-style: none;
  background: #f5f5f5;
  margin-bottom: 10px !important;
  isolation: auto !important;
}

.setup-wizard-nav > li:first-child > a,
.setup-wizard-nav > li:first-child > a:hover {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.setup-wizard-nav > li:first-child > a {
  padding-left: 15px !important;
}

.setup-wizard-nav > li > a.active,
.setup-wizard-nav > li > a.active div {
  color: var(--white) !important;
  background: var(--primary) !important;
  border-color: var(--primary) !important;
}

.setup-wizard-nav > li > a:before {
  content: " ";
  width: 34px;
  height: 50.5px;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(0, -50%);
  z-index: 1;
  background-image: url("../img/right-arrow2.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.setup-wizard-nav > li > a.active::before {
  background-image: url("../img/right-arrow-active.png");
}

.setup-wizard-nav > li > a.active .stepInfo i {
  color: var(--white) !important;
}

.setup-wizard-nav > li > a {
  border-color: #ededed !important;
  background: white !important;
}

.setup-wizard-nav > li > a:after {
  border-left-color: white !important;
}

.setup-wizard-nav > li > a.active::after {
  border-left-color: var(--primary) !important;
}

.newFunnelSetUp > li > a {
  display: flex !important;
  height: 67px;
  align-items: center;
}

.newFunnelSetUp > li > a div {
  display: flex;
  flex-direction: column;
}

.newFunnelSetUp > li > a:before {
  width: 45px !important;
  height: 67px !important;
}

.newFunnelSetUp > li > a,
.newFunnelSetUp > li > a:hover {
  padding: 14px 15px 14px 58px !important;
}

.newFunnelSetUp > li:first-child > a {
  padding-left: 15px !important;
}

/* user management & other related pages */
.delete-role {
  height: 28px;
  width: 28px;
  padding: 0;
  line-height: 30px;
  border-radius: 50%;
  font-size: 14px;
  color: #f16667;
  border: 1px solid #f16667;
}

.delete-role i {
  color: #f16667 !important;
  font-size: 15px !important;
}

.user-role {
  font-size: 15px;
  color: #575757;
  margin-bottom: 5px;
}

.tooltip-inner {
  max-width: 250px;
}

.table tr td {
  vertical-align: middle;
}

.back-btn {
  margin-right: 20px;
  margin-top: -4px;
  padding: 0 9px 0 8px;
  height: 28px;
  line-height: 28px;
  font-size: 15px;
}

.fb-btn-manage-business,
.google-btn-manage-business,
.yelp-btn-manage-business {
  color: var(--white);
  font-size: 15px;
  background: #3b5998;
  border: 1px solid #3b5998;
  padding: 2px 8px;
  margin-right: 5px;
}

.google-btn-manage-business {
  background: #db4437;
  border: 1px solid #db4437;
}

.yelp-btn-manage-business {
  background: #af0606;
  border: 1px solid #af0606;
}

.fb-btn-manage-business .fa,
.google-btn-manage-business .fa,
.yelp-btn-manage-business .fa {
  color: var(--white);
  margin-right: 0;
}

.img-profile {
  height: 22px;
  width: 22px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 5px;
}

.access-dropdown {
  min-width: 220px;
}

.access-dropdown .list-group .list-group-item,
.access-dropdown .see-all-btn {
  border-radius: 0 !important;
}

.access-dropdown .see-all-btn {
  background: var(--primary);
  color: var(--white);
}

.upload-image-wrap .profile-pg-img {
  max-width: 152px;
  width: 152px;
}

.upload-image-wrap .profile-pg-img .imagePreview {
  height: 134px;
  width: 134px;
  cursor: pointer;
}

.submit-btn-kiosk {
  margin: 20px 0 15px;
}

.customer-page .button-wrap-profile1 button:hover .fa,
.customer-page .button-wrap-profile1 button:focus .fa,
.customer-page .button-wrap-profile1 button:active .fa {
  color: var(--white);
}

.feedback-radio-btn {
  display: flex;
  justify-content: "flex-start";
}

.feedback-radio-btn .btn {
  width: 75px;
  max-width: 75px;
  margin-right: 20px;
  border-radius: 3px !important;
  cursor: pointer;
  padding: 6px 4px;
  line-height: 1;
}

.feedback-radio-btn .btn .fa {
  font-size: 19px;
  margin-right: 0;
}

.feedback-radio-btn .btn-outline-danger .fa {
  color: #f86c6b;
}

.feedback-radio-btn .outline-theme-btn .fa {
  color: var(--primary);
}

.feedback-radio-btn .outline-theme-btn.active .fa,
.feedback-radio-btn .btn-outline-danger.active .fa,
.feedback-radio-btn .btn-outline-danger:hover .fa,
.outline-theme-btn:hover .fa {
  color: var(--white);
}

.outline-theme-btn {
  border: 1px solid var(--primary);
}

.outline-theme-btn.active,
.outline-theme-btn:hover {
  border: 1px solid var(--primary);
  background: var(--primary);
  color: var(--white);
}

.custom-progress-bar {
  background: var(--primary);
}

/* dashboard  */
.dashboardPg .card {
  padding-top: 0;
  margin-bottom: 25px;
  margin-top: 20px;
}

.dashboardPg .card h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  padding: 15px 18px 14px;
  /* border-bottom: 1px solid #e1e1fb; */
  background-color: rgba(51, 179, 119, 0.8);
  color: var(--white);
}

.chartWrap .card-body {
  display: flex !important;
  flex-direction: row !important;
}

.chartWrap--chartIndicators {
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 50%;
  margin-top: -0.5px;
}

.chartIndicatorsTxt {
  margin-bottom: 15px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartIndicatorsTxt:last-child {
  margin-bottom: 10px;
}

.chartIndicatorsTxt span {
  font-weight: 600;
  margin-left: 4px;
}

.chartInsideText {
  position: absolute;
  width: 115px;
  height: 115px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chartInsideText h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 1px;
  margin-top: -2px;
}

.chartInsideText p {
  font-size: 12px !important;
  margin-bottom: 0;
}

.progressBarWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.progressBarWrap:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

/* .activitiesInfo {
  display: flex;
  margin-bottom: 30px;
  margin-top: 5px;
} */

.activitiesInfo div:first-child {
  margin-right: 35px;
}

.activitiesInfo i {
  font-size: 28px !important;
  color: #c4cfde;
}

.activitiesInfo h5 {
  font-size: 22px;
  margin: 2px 0 0 5px;
  font-weight: 600;
  color: var(--grey);
}

.activitiesInfo h5 span {
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  margin-left: 4px;
}

.activitiesWrap p {
  margin-bottom: 5px;
  color: var(--grey);
}

.activitiesWrap p span {
  font-weight: 700;
}

.activitiesWrap .progress {
  margin-bottom: 10px;
  height: 10px;
}

#profile-page .addBusinessCard {
  background-color: var(--white) !important;
  background-clip: border-box !important;
  border: 1px solid #e4e7ea !important;
  border-radius: 0.25rem !important;
  padding-top: 0 !important;
  box-shadow: none !important;
  margin-bottom: 15px !important;
}

#profile-page .addBusinessCard .card-header {
  background-color: #fafafa !important;
  border-bottom: 1px solid #ddd !important;
  padding: 0.52rem 1rem;
  text-align: left !important;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.addBusinessCard .card-header i {
  transition: all 0.3s;
}

.addBusinessCard .card-header.collapsed i {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.modal-header,
.customModalWrapper .MuiDialogTitle-root {
  padding: 0.8rem 1rem !important;
  border-bottom: 1px solid #dee2e6;
  background-color: rgba(51, 179, 119, 0.8);
}

.customModalWrapper .MuiDialogContent-root {
  padding: 18px 15px;
}

.customModalWrapper .custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.modal-footer,
.customModalWrapper .MuiDialogActions-root {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  padding: 0.5rem 1rem !important;
}

.customModalWrapper .theme-btn span,
.customModalWrapper .btn-danger span {
  color: #fff;
}

.modal-title,
.customModalWrapper .MuiTypography-h6 {
  font-size: 16px !important;
  line-height: 1;
  font-weight: 500;
  font-family: "Mulish", sans-serif !important;
  margin-bottom: 0 !important;
  color: var(--white) !important;
}

.customModalWrapper .cancel-btn {
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  padding: 4px 12px !important;
  font-size: 14px !important;
  height: 42px !important;
  line-height: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  background-color: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  color: #4f4f4f !important;
  font-family: "Mulish", sans-serif !important;
}

.customModalWrapper .theme-btn {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: white !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  padding: 4px 12px !important;
  font-size: 14px !important;
  height: 42px !important;
  line-height: 1 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  border-radius: 8px !important;
  font-family: "Mulish", sans-serif !important;
}

.customModalWrapper .theme-btn span {
  text-transform: capitalize;
}

.MuiInput-underline.Mui-focused:after {
  border-bottom: 1px solid #5c6873;
}

.MuiFormLabel-root.Mui-focused {
  color: #5c6873 !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #5c6873 !important;
}

.MuiInputBase-inputMarginDense {
  font-size: 15px !important;
  letter-spacing: 0.3px !important;
}

.MuiInputLabel-shrink {
  transform: translate(0, 0.5px) scale(0.75) !important;
}

.MuiDialogContentText-root {
  font-size: 15px !important;
  color: #303030 !important;
  letter-spacing: 0.3px !important;
}

.MuiDialog-paperWidthSm {
  max-width: 1240px !important;
}

.prettyEmail-option-wrapper {
  border: 1px solid #b5b6b6;
  padding: 15px;
  padding-bottom: 0px;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  /* width: 100%; */
}

.prettyEmail-option-wrapper img {
  height: 180px;
  width: 100%;
  border-radius: 8px;
}

.prettyEmail-option-wrapper p {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-top: 15px;
}

.prettyEmail-option-wrapper:hover {
  background-color: rgba(52, 178, 119, 0.6);
  border: 1px solid rgba(52, 178, 119, 0.6);
}

.prettyEmail-option-wrapper:hover p {
  color: #fff !important;
}

.prettyEmail-option-selected {
  background-color: rgba(52, 178, 119, 0.6);
  border: 1px solid rgba(52, 178, 119, 0.6);
  padding: 15px;
  cursor: pointer;
  padding-bottom: 0px;
  text-align: center;
  border-radius: 8px;
}

.prettyEmail-option-selected p {
  font-weight: 700;
}

.error-input {
  font-size: 13px;
  color: #f16667;
  display: block;
  margin-top: 0.25rem;
  padding-left: 3px;
}

.accordion__button {
  padding: 12px 12px 10px !important;
  background-color: var(--white) !important;
  outline: 0 !important;
}

.accordion__panel {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.btn-outline-secondary {
  color: #c8ced3;
  border: 1px solid #c8ced3 !important;
}

.text-underline {
  text-decoration: underline;
}

.page-item.active .page-link {
  background-color: var(--primary);
  border-color: var(--primary);
}

.customModalWrapper .delete-confirm-text,
.customModalWrapper .logout-confirm {
  font-size: 18px !important;
  margin-bottom: 0;
  text-align: center;
}

.funnelListBackBtn {
  border: none;
  background-color: white;
  margin-right: auto;
  padding: 4px 0;
  outline: 0 !important;
}

.MuiSlider-thumb,
.MuiSlider-track {
  background-color: var(--primary) !important;
}

.checkboxCustom .custom-control-label::before,
.checkboxCustom .custom-control-label::after {
  top: 4px;
}

.selectedColor {
  height: 42px;
  border: 1px solid #b5b6b6;
  cursor: pointer;
  width: 130px;
  border-radius: 4px;
}

.selectedColor ~ .theme-btn {
  background-color: var(--white) !important;
  color: var(--primary) !important;
}

.stepWrap ~ .theme-btn {
  margin-top: 18px;
}

.stepWrap {
  border: 1px solid #eaf0fd;
  padding: 13px 5px 14px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 6px;
  list-style-type: none;
  position: relative;
  counter-increment: css-counter 1;
}

.stepWrap:before {
  content: counter(css-counter);
  position: absolute;
  top: 6px;
  left: -9px;
  border: 1px solid #33b377;
  background-color: var(--white);
  border-radius: 4px;
  padding: 0 4px;
  height: 18px;
  line-height: 17px;
  font-size: 12px;
  font-weight: 700;
  color: #33b377;
}

.stepWrap p {
  font-size: 15px !important;
  letter-spacing: 0.3px;
  margin-bottom: 0;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 23px;
  /* fallback */
  max-height: 46px;
  /* fallback */
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.stepWrap i {
  font-size: 16px;
  color: #aaa;
}

.stepWrap .stepIcon {
  font-size: 30px;
  margin-right: 15px;
  width: 30px;
}

.stepWrap:hover {
  border-color: var(--primary);
  background-color: rgba(51, 179, 119, 0.7);
}

.stepWrap:hover i,
.stepWrap:hover p,
.stepWrap:hover .stepIcon {
  color: #fff !important;
}

.stepWrap:hover .stepIcon {
  cursor: pointer;
}

.addStepWrap select.form-control {
  width: 100% !important;
}

/* custom date range css */
.customDateRangeWrap {
  border: 1px solid #b5b6b6;
  border-radius: var(--border-radius);
  display: flex;
  margin-left: 8px;
}

.customDateRangeWrap .form-control {
  border: 0;
  height: 40px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/* date range plugin css */
.DateRangePickerInput__withBorder {
  border-radius: var(--border-radius) !important;
  border: 0 !important;
  border-left: 1px solid #c2c2c2 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.DateRangePickerInput__withBorder .DateInput {
  border-radius: var(--border-radius);
}

.DateRangePicker_picker {
  z-index: 99 !important;
}

.DateInput {
  width: 95px !important;
}

.mainContent input:not(.form-check-input).DateInput,
.mainContent input:not(.form-check-input).DateInput_input {
  height: 40px !important;
}

.DateInput_input {
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-clip: padding-box;
  color: rgba(45, 55, 72, 0.6) !important;
  font-family: "Mulish", sans-serif;
  font-size: 14px !important;
  border: 0 !important;
  padding: 6px !important;
  padding-left: 7px !important;
  border-radius: var(--border-radius) !important;
  outline: 0 !important;
}

.DateRangePickerInput_arrow_svg {
  width: 16px !important;
  height: 16px !important;
  margin-top: -4px;
  margin-right: 0px;
  margin-left: -3px;
}

.CalendarMonth_caption {
  font-size: 16px !important;
  color: var(--grey) !important;
}

.DayPicker_weekHeader_li {
  color: rgba(45, 55, 72, 0.6);
}

.CalendarDay__default {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: var(--grey) !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #33b377 !important;
  color: var(--white) !important;
  border-color: #33b377 !important;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span,
.CalendarDay__selected_span:hover {
  background: rgba(#33b377, 0.5) !important;
  border-color: rgba(#33b377, 0.5) !important;
}

.CalendarMonth_table {
  margin-top: 3px;
  box-shadow: none;
}

.DayPicker__withBorder {
  border-radius: 4px !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span {
  background: #d6f0e4 !important;
}

.loaderWrap {
  width: 300px;
  height: 250px;
  text-align: center;
}

.loaderWrap p {
  font-size: 16px !important;
  margin-top: 10px;
  color: #34b277;
  text-transform: lowercase;
  font-weight: 600;
  font-family: "Mulish", sans-serif;
}

.loaderWrap p::first-letter {
  text-transform: uppercase;
}

.removeMedia {
  position: absolute;
  z-index: 999;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #f16667;
  background-color: var(--white);
  padding: 0;
  line-height: 22px;
  font-size: 14px;
  right: 5px;
  top: 5px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.removeMedia i {
  color: #f16667;
}

.table td .plusIcon {
  font-size: 15px !important;
  color: #28a745 !important;
  margin-top: 3px;
}

.page-header .button-wrap-profile-new > *:not(:last-child) {
  margin-right: 0.5rem !important;
}

.mainContent .permissionCheckbox {
  margin-top: 5px;
  margin-bottom: 10px;
}

.mainContent .permissionCheckbox input:not(.form-check-input) {
  height: unset !important;
}

.permissionCheckbox .custom-control-label::before,
.permissionCheckbox .custom-control-label::after {
  top: 4px;
}

.feedbackTab .stepWrap ~ .theme-btn {
  margin-left: auto;
}

.feedbackOptions input {
  border-radius: var(--border-radius) !important;
}

.feedbackOptionCreate .input-group-text {
  background-color: #33b377 !important;
  border-color: #33b377 !important;
  width: 90px;
  justify-content: center;
}

.feedbackOptionCreate .input-group-text i {
  color: var(--white) !important;
}

.feedbackOptionCreate + input {
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.businessLogo {
  height: 42px;
  width: 100px;
  min-width: 100px;
  object-fit: contain;
  margin-right: 20px;
}

.snap21,
.creditKarma,
.menupix,
.manta,
.realtor {
  transform: scale(1.1);
}

.autoBytel,
.homeAdvisor,
.caring,
.uCompare,
.homesDotCom,
.openTable,
.insiderPages,
.yahoo,
.nextDoor,
.expPros {
  transform: scale(1.2);
}

.hotelsCombined,
.realPatent,
.zomato,
.superPages,
.oph {
  transform: scale(1.3);
}

.hotelsDotCom,
.kayak,
.priceline,
.avvo,
.lawyersDotCom,
.ratemds,
.rent,
.merchantCircle,
.oyster {
  transform: scale(1.4);
}

.walletHub,
.carConnection {
  transform: scale(1.5);
}

.seniorHome {
  transform: scale(1.6);
}

.autoTrader,
.superLawyers {
  transform: scale(1.7);
}

.customReviewSiteWrap {
  border: 1px solid #c8ced3;
  padding: 20px;
  border-radius: var(--border-radius);
  margin-top: 15px;
}

.customReviewSiteWrap .form-group {
  display: flex;
}

.authPgTitle {
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.3px;
  margin-bottom: 8px;
}

.chipsContainer {
  width: 1000px;
}

#profile-page .feedback-alert {
  box-shadow: none !important;
}

.pagination ul.pagination {
  margin-bottom: 0;
}

.MuiTypography-body1 {
  font-family: "Mulish" !important;
}

.customerFeedbackCard .card-header {
  background-color: rgba(51, 179, 119, 0.7);
  color: var(--white) !important;
}

.customerFeedbackCard .card-header i {
  color: var(--white);
}

.templateOptions {
  margin-bottom: 12px !important;
}

.templateOptions li {
  padding: 5.5px 0;
  font-size: 14px;
  border: 0;
  color: var(--grey);
  font-weight: 500;
}

.templateOptions li i {
  vertical-align: -1px;
  margin-left: 10px;
  color: var(--light-grey);
  cursor: pointer;
}

.tabOnlyView {
  display: none;
}

/* media styles  */
@media (min-width: 1200px) {
  .mainContent header .navbar-nav li:last-child a {
    padding-right: 0 !important;
  }
}

@media (max-width: 1199.98px) {
  .fb-btn-manage-business,
  .google-btn-manage-business,
  .yelp-btn-manage-business {
    margin-bottom: 7px;
  }

  .public-page .login_banner h1 {
    font-size: 30px !important;
  }
}

@media all and (max-width: 991.98px) and (min-width: 768px) {
  #profile-page .add-module-wrap .card-header {
    font-size: 15px;
  }

  #sequence-page .add-module-wrap .card-body button {
    font-size: 13px;
    width: 105px;
  }

  #profile-page .button-wrap-profile button {
    width: 200px;
  }

  .customer-page .card-body {
    width: 100% !important;
  }

  .customer-page .button-wrap-profile1 button {
    font-size: 14px;
  }

  #sequence-page .inner-card-padding {
    padding: 40px 20px;
  }

  #sequence-page .card-header-big .remove-card-header {
    margin-top: 10px;
  }

  #profile-page .new-card-body {
    width: 100%;
  }

  .app-header .nav-item {
    font-size: 12px;
    padding: 0 4px;
  }

  .dashboardPg .col-12.col-lg-5 .card:first-child {
    margin-top: 2px;
  }

  #profile-page .button-wrap-profile-new {
    margin-top: 20px;
  }

  #profile-page .businessPg .button-wrap-profile-new {
    margin-top: 0;
  }
}

@media (min-width: 991px) {
  .w-lg-75 {
    width: 75% !important;
  }
}

@media all and (max-width: 991.98px) {
  .setup-wizard-page .card-body {
    width: 100% !important;
  }

  .add-module-wrap .card-body .module {
    width: 100px;
  }

  .added-module {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .added-module .module-added {
    min-width: 70px;
    max-width: 70px;
    margin-bottom: 0;
    margin-right: 20px;
  }

  .added-module-wrap {
    flex-direction: column;
  }

  .setup-wizard-nav > li > a,
  .setup-wizard-nav > li > a:hover {
    padding: 8px 12px 8px 25px !important;
    font-size: 12px !important;
  }

  .setup-wizard-nav > li > a div {
    font-size: 12px !important;
  }

  .setup-wizard-nav > li > a:before {
    width: 24px;
    height: 36px;
    transform: translate(-1px, -50%);
  }

  .newFunnelSetUp > li > a,
  .newFunnelSetUp > li > a:hover {
    padding: 8px 12px 8px 43.5px !important;
  }

  .setup-wizard-nav > li:first-child > a,
  .newFunnelSetUp > li:first-child > a {
    padding-left: 8px !important;
  }

  .stepInfo {
    right: -2px;
    top: 4px;
  }

  #profile-page .add-module-wrap {
    width: 165px;
  }

  #profile-page .feedback-card,
  .custom-left-margin {
    margin-left: 190px;
  }

  table td,
  table th {
    white-space: nowrap;
    max-width: 100% !important;
  }

  .main .container-fluid {
    padding: 0 20px !important;
  }
}

@media all and (max-width: 767.98px) {
  .submit-btn-kiosk {
    margin: 27px auto 30px;
    display: block;
  }

  #profile-page .profile-head {
    font-size: 21px;
  }

  .tabOnlyView {
    display: flex;
    background: var(--white);
    padding: 50px 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    text-transform: uppercase;
    text-align: center;
    color: var(--grey);
  }

  .tabOnlyView .logoTabOnly {
    max-width: 220px;
    margin-top: -100px;
  }

  .tabOnlyView p {
    font-size: 20px !important;
    font-weight: 600;
    margin-bottom: 22px;
  }

  .tabOnlyView h4 {
    font-size: 23px;
    font-weight: 700;
  }
}

@media all and (max-width: 575.98px) {
  .tabOnlyView p {
    font-size: 14px !important;
    margin-bottom: 18px;
  }

  .tabOnlyView h4 {
    font-size: 21px;
  }
}

.selected-step {
  color: white !important;
  background-color: var(--primary) !important;
}

.selected-step-error {
  color: white !important;
  background-color: red !important;
  border-color: red;
}

.add-license-btn {
  background: #34807f;
  margin-left: 10px;
  border-radius: var(--border-radius) !important;
  cursor: pointer;
}

.add-license-btn i,
.add-license-key-btn i {
  color: #4dbd74 !important;
}

.add-license-key-btn {
  margin-left: 10px;
  border-radius: var(--border-radius) !important;
  cursor: pointer;
  border-color: #34807f;
  background: var(--white);
}

.remove-license-btn {
  background: var(--white);
  margin-left: 10px;
  border-color: red !important;
  border-radius: var(--border-radius) !important;
  cursor: pointer;
}

.remove-license-btn i {
  color: #dc3545 !important;
}

.key-list-col {
  /* border-right: 1px solid #e5e5e5; */
  height: 200px;
}

.keys-wrapper {
  padding: 10px 0;
}
.license-key {
  padding: 7px;
  background: #e5e5e55c;
  border-radius: 5px;
  margin: 6px 10px 6px 0;
  font-weight: 500;
  color: #35817f;
  display: inline-block;
}

.toast {
  flex: 0;
  background: #ffb7bd !important;
  color: #721c24 !important;
}

.code-select {
  margin-right: 8px;
}

.mandatorySign {
  font-size: 85%;
  color: #f16667;
  top: -0.4em;
}
